import Link from "next/link";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const NotFound = () => {
  return (
    <>
      <Navbar />
      <div className="h-screen w-full bg-zinc-100">
        <div className="flex flex-col items-center justify-center pt-32">
          <p className="text-2xl font-bold text-zinc-700 sm:text-4xl">
            페이지를 찾지 못했습니다
          </p>
          <div className="relative mt-20 ">
            <div className="absolute inset-0 z-0 flex h-[4.75rem] w-64 items-center justify-center rounded-[1.75rem] border-[3px] border-zinc-700 bg-zinc-700 "></div>
            <Link href="/">
              <a
                href="#사전신청"
                className="z-100 flex h-[4.75rem] w-64 items-center justify-center rounded-[1.75rem] border-[3px] border-zinc-700 bg-white text-lg font-bold transition-all duration-150 ease-in-out will-change-transform hover:translate-y-[-4.5px] hover:translate-x-[-2px] focus:translate-y-[-1px] focus:translate-x-[-1px] focus:bg-zinc-300 active:duration-100 "
              >
                홈페이지로 돌아가기
              </a>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
